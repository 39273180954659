import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    useDisclosure,
    Input,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Table,
    ModalFooter,
    Textarea,
    User,
    Spinner,
    SelectItem,
    Select
} from "@nextui-org/react";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BaseURL} from "../variables/Variables";

export const ApproveDetailsModal = ({id, open, external, name, approveUI, setItem, setListUpdated, disabled, status}) => {
    const buttonRef = useRef(null);
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [data, setData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [rows, setRows] = useState([]);
    const columns = [
        {
            key: "id",
            label: "Артикул",
        },
        {
            key: "name",
            label: "Наименование товара",
        },
        {
            key: "count",
            label: "Количество",
        },
        {
            key: "pack",
            label: "Упаковка",
        },
        {
            key: "price",
            label: "Цена за единицу",
        },
        {
            key: "sum",
            label: "Сумма",
        }
    ];

    useEffect(() => {
        fetchDetails(id,open,external);
    }, [id, open]);


    const fetchDetails = (id, open, external) => {
        console.log("fetch");
        if (id){
            axios.get(`${BaseURL}/users/approve/${id}/details/`).then(
                res => {
                    setData(res.data);
                    setUpdatedData(res.data);
                    setRows(res.data.rows);
                    if(open && external){
                        setItem({name: name});
                        buttonRef.current.click();
                    }
                }
            ).catch(
                err => {
                    console.log(err.response.data);
                }
            )
        }
    }

    const accept = () => {
        setIsLoading(true);
        if (status === "Возвращен"){

            const data = new FormData();

            data.append("name", updatedData.name);
            data.append("comment", updatedData.comment);
            data.append("delivery_date", updatedData.delivery_date);
            data.append("supplier", updatedData.supplier);
            data.append("rows", JSON.stringify(rows));
            data.append("template", JSON.stringify(""));

            axios.post(`${BaseURL}/users/approve/${id}/edit/`, data).then(
                res => {
                    window.location.reload();
                    setIsLoading(false);
                    setIsOpen(false);
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
        }else{
            axios.post(`${BaseURL}/users/approve/${id}/accept/`, {"comment": comment}).then(
                res => {
                    window.location.reload();
                    setIsLoading(false);
                    setIsOpen(false);
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )
        }
    }

    const deny = () => {
        setIsLoading(true);
        axios.post(`${BaseURL}/users/approve/${id}/deny/`, {"comment": comment}).then(
            res => {
                window.location.reload();
                setIsLoading(false);
                setIsOpen(false);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    const changeInput = (e, index, name) => {
        const temp_rows = [...rows];
        temp_rows[index][`${name}`] = e.target.value;
        if (name === "count" || name === "price"){
            temp_rows[index].sum = temp_rows[index].count * temp_rows[index].price;
        }
        setRows(temp_rows);
    }

    const deleteRow = (index) => {
        const temp_rows = [...rows]
        temp_rows.splice(index, 1);
        setRows(temp_rows);
    }

    return (
        <>
            <Button ref={buttonRef} onPress={() => {setIsOpen(true)}} size={"sm"} className={`font-bold ${external && "hidden"}`}>Подробнее</Button>
            <Modal
                isDismissable={false}
                key={id}
                isOpen={isOpen}
                onOpenChange={() => {setIsOpen(false)}}
                placement="top-center"
                className={"relative"}
                onClose={() => {setIsLoading(false);
                    setData({});
                }}
                hideCloseButton={true}
            >
                <ModalContent className={"pb-[20px]"}>
                    {(onClose) => {
                        return(
                            <>
                                {
                                    isLoading &&
                                    <div className={"absolute top-0 right-0 bg-[#FFFFFFB0] w-full h-full z-50 flex justify-center items-center"}>
                                        <Spinner/>
                                    </div>
                                }
                                <ModalHeader className="flex flex-row justify-between items-center gap-1"><p>{name}</p>
                                    <Button onClick={() => {setIsOpen(false)}}>Закрыть</Button>
                                </ModalHeader>
                                <ModalBody className={"max-h-[400px] overflow-auto"}>
                                    <p>*{updatedData.comment}</p>
                                    {
                                        data.supplier &&
                                        <>
                                            <Input
                                                disabled={disabled}
                                                label={"Поставщик"}
                                                value={updatedData.supplier}
                                                required={true}
                                                onChange={(e) => {setUpdatedData({...updatedData, supplier: e.target.value})}}
                                            />
                                            <Input
                                                disabled={disabled}
                                                label={"Дата поставки"}
                                                value={updatedData.delivery_date}
                                                type={"date"}
                                                required={true}
                                                onChange={(e) => {setUpdatedData({...updatedData, delivery_date: e.target.value})}}
                                            />
                                        </>
                                    }
                                    {
                                        template && template.map((input, index) => (
                                            <Input
                                                disabled={disabled}
                                                label={input.label}
                                                value={input.value}
                                                required={input.required}
                                                key={index}
                                                type={input.type}
                                            />
                                        ))
                                    }
                                    {
                                        rows &&
                                        <Table aria-label="Example table with dynamic content">
                                            <TableHeader columns={columns}>
                                                {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    rows?.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell className={"rounded-none"}>
                                                                <Input
                                                                    disabled={disabled}
                                                                    className={"w-[120px]"}
                                                                    required={true}

                                                                    value={row.id}
                                                                    onChange={e => {changeInput(e, index, "id")}}
                                                                />
                                                            </TableCell>
                                                            <TableCell className={"rounded-none"}>
                                                                <Input
                                                                    disabled={disabled}
                                                                    className={"w-[120px]"}
                                                                    required={true}
                                                                    value={row.name}
                                                                    onChange={e => {changeInput(e, index, "name")}}
                                                                />
                                                            </TableCell>
                                                            <TableCell className={"rounded-none"}>
                                                                <Input
                                                                    disabled={disabled}
                                                                    className={"w-[120px]"}
                                                                    required={true}
                                                                    value={row.count}
                                                                    type={"number"}
                                                                    onChange={e => {changeInput(e, index, "count")}}
                                                                />
                                                            </TableCell>
                                                            <TableCell className={"rounded-none"}>
                                                                {
                                                                    status === "Возвращен" ?
                                                                        <Select
                                                                            required={true}
                                                                            value={""}
                                                                            onChange={e => {
                                                                                row.pack = e.target.value
                                                                            }}
                                                                            className="max-w-xs w-[100px]"
                                                                        >
                                                                            <SelectItem key={"Шт."} value={"Шт."}>Шт.</SelectItem>
                                                                            <SelectItem key={"Литр"} value={"Литр"}>Литр</SelectItem>
                                                                            <SelectItem key={"Банка"} value={"Банка"}>Банка</SelectItem>
                                                                        </Select>
                                                                        :
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            required={true}
                                                                            value={row.pack}
                                                                            // onChange={e => {changeInput(e, index, "pack")}}
                                                                        />
                                                                }
                                                            </TableCell>
                                                            <TableCell className={"rounded-none"}>
                                                                {
                                                                    status === "Возвращен" ?
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            required={true}
                                                                            value={row.price}
                                                                            onChange={e => {changeInput(e, index, "price")}}
                                                                            type={"number"}
                                                                        />
                                                                        :
                                                                        <Input
                                                                            disabled={disabled}
                                                                            className={"w-[120px]"}
                                                                            required={true}
                                                                            value={row.price}
                                                                            type={"number"}
                                                                        />
                                                                }
                                                            </TableCell>
                                                            <TableCell className={"rounded-none"}>
                                                                <Input
                                                                    disabled={disabled}
                                                                    className={"w-[120px]"}
                                                                    value={row.sum}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    }
                                </ModalBody>
                                {
                                    status !== "Возвращен" &&
                                    <>
                                        <div className={"flex px-[26px] py-[10px] gap-[12px]"}>
                                            {
                                                data?.persons?.map((person, index) => {
                                                    if (person.is_approved){
                                                        return(
                                                            <div key={index} className={"flex flex-col justify-start items-center gap-[8px]"}>
                                                                <img draggable={false} src={person.user.avatar} className={"w-[40px] h-[40px] object-cover rounded-full bg-gray-400 border-[2px] border-[green]"}/>
                                                                <p className={"text-[12px] max-w-[80px] text-center"}>{person.user.username}</p>
                                                            </div>
                                                        )
                                                    }else{
                                                        return (
                                                            <div key={index} className={"flex flex-col justify-start items-center gap-[8px]"}>
                                                                <img draggable={false} src={person.user.avatar} className={"w-[40px] h-[40px] object-cover rounded-full"}/>
                                                                <p className={"text-[12px] max-w-[80px] text-center"}>{person.user.username}</p>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <p className={"text-[12px] px-[26px]"}>Аватары утвердивших помечены зеленым цветом</p>
                                    </>
                                }
                                {
                                    approveUI &&
                                    <ModalFooter className={"flex flex-col w-full"}>
                                        {status !== "Возвращен" ?
                                            <Textarea
                                                onChange={(e) => {setComment(e.target.value)}}
                                                label={"Комментарий"}
                                                placeholder={"Ваш комментарий (Можно оставить пустым)"}
                                            /> :
                                            <Button onClick={() => {
                                                const temp_rows = [...rows]
                                                temp_rows.push({
                                                    "id": "",
                                                    "name": "",
                                                    "pack": "",
                                                    "count": 0,
                                                    "price": 0,
                                                    "sum": ""
                                                });
                                                setRows(temp_rows);
                                            }}>
                                                Добавить строчку
                                            </Button>
                                        }
                                        <div className={"flex w-full gap-[12px]"}>
                                            {status !== "Возвращен" && <Button onClick={deny} fullWidth={true}>Вернуть</Button>}
                                            <Button onClick={accept} className={"bg-[#FCE345]"} fullWidth={true}>Отправить</Button>
                                        </div>
                                    </ModalFooter>
                                }
                            </>
                        )
                    }}
                </ModalContent>
            </Modal>
        </>)}
